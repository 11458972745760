<template>
  <div>
    <CRow>
      <CCol lg="10">
        <CTableAccountInfo :items="data" striped :caption="caption" />
      </CCol>
      <!-- <CCol lg="6"> -->
      <!--<div> TODO Later
      <CRow>
      <CCol sm="6" md="4">
        <CWidgetProgressIcon
          header="130.5"
          text="Overall score"
          color="gradient-danger"
          inverse
        >
          <CIcon name="cil-speedometer" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" md="4">
        <CWidgetProgressIcon
          header="11"
          text="IAM USERS"
          color="gradient-warning"
          inverse
        >
          <CIcon name="cil-speedometer" height="36"/>
        </CWidgetProgressIcon>        
      </CCol>
      </CRow>
      </div>-->
      <!-- </CCol> -->
    </CRow>
  </div>
</template>

<script>
import CTableAccountInfo from "./TableAccountInfo.vue";
import axios from "axios";

async function getAccountInfo(accountId, accessToken) {
  try {
    const config = {
      headers: {
        authorizationToken: "Bearer " + accessToken,
      },
    };
    const URL = process.env.VUE_APP_BACKEND_BASEURL + "/accounts/" + accountId;
    const response = await axios.get(URL, config);

    console.log("AccountInfo", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      // status code that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // no response
      console.log(error.request);
    } else {
      // other
      console.log("Error", error.message);
    }
    throw new Error("There was an error while fetching your data");
  }
}

export default {
  name: "Tables",
  components: { CTableAccountInfo },
  data() {
    return {
      accountId: this.$route.params.id,
      data: undefined,
      caption: "AWS Account Metadata (" + this.$route.params.id + ")",
    };
  },
  async created() {
    let accessToken = await this.$auth.getAccessToken();
    let metadata = { Account: {} };

    try {
      metadata = await getAccountInfo(this.accountId, accessToken);
    } catch (err) {
      this.$store.commit("showErrorModal", ["Error", err.message]);
    }
    this.data = [];
    Object.keys(metadata.Account).map((key) => {
      if (key == "AccountOwners") {
        // metadata.Account[key].map(k => {
        //   console.log(k["Login"]);
        //   this.data.push({ Attribute: key, Value: k["Login"] });
        let owners = "";
        let ownername = "";
        for (let i in metadata.Account[key]) {
          console.log(metadata.Account[key][i]["Login"]);
          if (metadata.Account[key][i]["DisplayName"] == "-") {
            ownername = metadata.Account[key][i]["Login"];
          } else {
            ownername = metadata.Account[key][i]["DisplayName"];
          }
          owners = owners + ownername + ", ";
        }
        this.data.push({
          Attribute: key,
          Value: owners.slice(0, -2),
        });
        // console.log(metadata.Account[key]);
      } else {
        this.data.push({ Attribute: key, Value: metadata.Account[key] });
      }
    });
    console.log("data", this.$root.currentAccount);
  },
};
</script>